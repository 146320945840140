import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

import { Layout } from 'containers';
import { mapStateToProps, FORMAT_GRAPH_DATA } from 'utilities';
import { GET_BOOKINGS } from 'actions/booking';
import { PAGES, BASE_URL } from 'config';
import { BookingCard } from 'components';


class UserPage extends PureComponent {
  constructor(props) {
    super(props);
    if (props.user.authenticated) {
      GET_BOOKINGS(props.dispatch, props.user.session.token, props.user.session.email);
    }

    this.state = {
      tours: FORMAT_GRAPH_DATA(props.data.allTourPages),
    };
  }

  /**
   * Get tour by ID
   * @param tourID
   */
  getTour(tourID) {
    const { tours } = this.state;
    return tours.find(tour => tour.api_id === tourID);
  }

  render() {
    const { user } = this.props;
    return (
      <Layout page="user">
        <Helmet
          title="Manage Tours"
          link={[{ rel: 'canonical', href: `${BASE_URL}/user/` }]}
          meta={[{ name: 'robots', content: 'noindex,nofollow' }]}
        />

        <section className="l-section">
          <div className="l-container">
            <h1 className="c-heading c-heading--h1">Manage Tours</h1>
            {user.bookings.bookings.length === 0
              ? (
                <div>
                  <h4 className="c-heading c-heading--h4">You have no tours</h4> <br />
                  <Link to={PAGES.TOURS.PATH} className="c-button c-button--primary">Browse tours</Link>
                </div>
              ) : '' }
            <div className="l-grid l-grid--3">
              {user.bookings.bookings.map(booking => (
                <BookingCard
                  key={booking.id}
                  tour={this.getTour(booking.tour_id)}
                  booking={booking}
                />
              ))}
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

UserPage.propTypes = {
  user: PropTypes.object.isRequired,
  data: PropTypes.any.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export const query = graphql`
  query {
    allTourPages(
      sort: {fields: [order], order: ASC},
    ) {
      edges {
        node {
          api_id
          number_of_days
        }
      }
    }
  }
`;

export default connect(mapStateToProps)(UserPage);
